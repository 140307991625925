const config = {
  apiBaseUrl: process.env.VUE_APP_OSIGU_API_BASE_URL || 'https://api.dev.osigu.com',
  getTokenMaxAttempts: process.env.VUE_APP_GET_TOKEN_MAX_ATTEMPTS || 5,
  getTokenAttemptDelayInSeconds: process.env.VUE_APP_GET_TOKEN_ATTEMPTS_DELAY_IN_SECONDS || 1,
  clientId: process.env.VUE_APP_CLIENT_ID || 'beneficiaries-web',
  clientSecret: process.env.VUE_APP_CLIENT_SECRET || 'fb1a2348-468f-4f98-b41d-5592e5e32ed1',
  fileSize: process.env.VUE_APP_FILE_SIZE || '10485760',
  applicationSlug: process.env.VUE_APP_APPLICATION_SLUG || 'beneficiaries-web',
  bugsnagApiKey: process.env.VUE_APP_BUGSNAG_KEY || 'c90d24e0a05da0cd46f11c9ed740d957',
  bugsnagReleaseStage: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE || 'development',
  bugsnagEnabledReleaseStages: process.env.VUE_APP_BUGSNAG_ENABLED_RELEASE_STAGES || 'production',
  intercomId: process.env.VUE_APP_INTERCOM_APP_ID || 'mvgu8g25',
  intercomKey: process.env.VUE_APP_INTERCOM_KEY || 'q0qFcEGh1_eWsiG0J3sGHp3Evwt_R7DUAQwfaiuC',
  theme: process.env.VUE_APP_THEME || 'OSIGU'
}

export { config }