import arcService from '@/services/arc-api.service'
import plansService from '@/services/plans-api.service'
import { getFieldsByForm } from '@/utils/forms.utils'
import vuei18n from '@/plugins/i18n';
import {
  AUTHORIZATION_LOAD_ERROR,
  AUTHORIZATION_LOAD_SUCCESS,
  AUTHORIZATION_LOADING,
  AUTHORIZATION_COMMENT_LOADING,
  AUTHORIZATION_FORM_LOAD_SUCCESS,
  AUTHORIZATION_SPONSOR,
  SET_CURRENT_FORM,
  SET_CURRENT_STEP,
  SET_ERROR,
  ADD_FILES,
  SET_STEP_ERROR,
  SET_BENEFICIARY,
  SET_BENEFICIARY_ERROR,
  SET_INPUTS,
  RESET_AUTHORIZATION_STATE,
  SET_REIMBURSEMENT_STATUS,
  SET_CLAIM_TYPE,
  SET_LIST_AUTHORIZATION,
  SET_AUTHORIZATION,
  SET_AUTHORIZATION_ERROR,
  SET_SPONSOR_SLUG,
  SET_IS_REIMBURSEMENT,
  SET_COUNTRY_CODE,
  SET_CURRENCY_CODE,
  SET_CURRENT_COVERAGE,
  REMOVE_FILE,
  SET_LOADING_FILE
} from '../mutation-types'
import router from '@/router'
import { camelizeKeys, decamelizeKeys } from 'humps';
import Bugsnag from '@bugsnag/browser';

const getAuthorizations = async ({ commit }, filters) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const response = await arcService.getAuthorizationList(filters);
    let camelizeResponse = camelizeKeys(response.data);

    if(filters.orderBy === "coverageType"){
      camelizeResponse.content.sort((a,b)=> {

        let valueA = vuei18n.t(arcService.getType(a[filters.orderBy]));
        let valueB = vuei18n.t(arcService.getType(b[filters.orderBy]));

        if(filters.sort === "ASC"){
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0
        }
        if (filters.sort === "DESC"){
          return valueB > valueA ? 1 : valueB < valueA ? -1 : 0
        }

      });
    }

    commit(AUTHORIZATION_LOAD_SUCCESS, camelizeResponse)
    commit(AUTHORIZATION_LOADING, false)
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const getAuthorizationsForm = async ({ commit, state }) => {
  try {
    const response = await arcService.getAuthorizationForm(state.sponsorSlug);
    const data = camelizeKeys(response.data)

    const formFields = getFieldsByForm(data)
    const formFieldsKeys = Object.keys(formFields)
    if (formFieldsKeys.length === 1 ) {
      formFields[formFieldsKeys[0]].shift()
      commit(SET_CURRENT_FORM, formFieldsKeys[0])
    }
    commit(AUTHORIZATION_FORM_LOAD_SUCCESS, { response: data, formsByStep: formFields })
    commit(AUTHORIZATION_LOADING, false)
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const getSponsorConfiguration = async ({ commit, state}) => {
  try {
    const response = await arcService.getSponsorConfiguration(state.sponsorSlug);
    const data = camelizeKeys(response.data)
    commit(AUTHORIZATION_SPONSOR, data)
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)    
  }
}

const getBeneficiary = async ({ commit, rootState }) => {
  try {
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const sponsorResponse = await plansService.getBeneficiary(beneficiaryId);
    const beneficiary = camelizeKeys(sponsorResponse.data)
    commit(SET_BENEFICIARY, beneficiary)
    commit(SET_SPONSOR_SLUG, beneficiary.sponsorSlug)
    commit(SET_COUNTRY_CODE, beneficiary.nationalityCountryCode)
    commit(SET_CURRENCY_CODE, beneficiary.currencyCode)
    sessionStorage.setItem('sponsorSlug', beneficiary.sponsorSlug)
    sessionStorage.setItem('countryCode', beneficiary.nationalityCountryCode)
    sessionStorage.setItem('currencyCode', beneficiary.currencyCode)
  } catch(error) {
    commit(SET_BENEFICIARY_ERROR, error)
  }
}

const setCurrentForm = ({ commit, state, dispatch }, payload) => {
  const form = state.authorizationForms.find(form => form.formSlug === payload)
  dispatch('setIsReimbursement', form.reimbursement)
  commit(SET_CURRENT_FORM, payload)
}

const setCurrentStep = ({ commit }, payload) => {
  commit(SET_CURRENT_STEP, payload)
}

const setCurrentCoverage = ({ commit }, payload) => {
  commit(SET_CURRENT_COVERAGE, payload)
}

const nextStep = ({ commit, state, dispatch, getters }, payload) => {
  dispatch('setError',{hasError: false, error: null})
  if (state.currentStep === payload) {
    const { authorizationForms, currentForm } = getters;
    const form = authorizationForms.find(form => form.formSlug === currentForm)
    if (form && !form.reimbursement) {
      dispatch('sendAuthorization')
    } else {
      dispatch('sendReimbursementV2')
    }
    
  } else {
    commit(SET_CURRENT_STEP, state.currentStep + 1)
  }
}

const lastStep = ({ commit, state, dispatch }) => {
  if (state.currentStep === 1) {
    dispatch('resetAuthorizationState')
    router.push('/home')
  } else {
    commit(SET_CURRENT_STEP, state.currentStep - 1)
    dispatch('setStepError',true);
    dispatch('setError',{hasError: false, error: null})
  }
}

const sendReimbursement = async ({ commit, state, rootState, dispatch }) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const request = decamelizeKeys({ beneficiaryId, formSlug: state.currentForm, inputs: state.inputs })
    const response = await arcService.sendReimbursement(request)
    const reimbursement = camelizeKeys(response.data)
    dispatch('sendReimbursementFiles', reimbursement.reimbursementId)
  } catch (error) {
    const response = error.response
    if (response) {
      let errorMessage = null
      const status = response.status
      if (status === 422) {
        const exceptionData = response.data
        if (exceptionData.errors) {
          if (exceptionData.errors.length > 0) {
            errorMessage = exceptionData.errors[0].message;
          } 
        }
      } else if (status === 500) {
        const exceptionData = response.data
        errorMessage = exceptionData.message
      }
      
      dispatch('setError',{hasError: true, error: errorMessage})
    }
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const sendReimbursementV2 = async ({ commit, state, dispatch, rootState }) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const files = state.files.map(file => file.fileId)
    const request = decamelizeKeys({ beneficiaryId, formSlug: state.currentForm, inputs: state.inputs, files })
    await arcService.sendReimbursementV2(request)
    commit(SET_CURRENT_STEP, 1)    
    commit(AUTHORIZATION_LOADING, false)
    commit(RESET_AUTHORIZATION_STATE)
    dispatch('setReimbursementStatus', 'SUCCESS')
    router.push('/home')
  } catch (error) {
    const response = error.response
    if (response) {
      let errorMessage = null
      const status = response.status
      if (status === 422) {
        const exceptionData = response.data
        if (exceptionData.errors) {
          if (exceptionData.errors.length > 0) {
            errorMessage = exceptionData.errors[0].message;
          } 
        }
      } else if (status === 500) {
        const exceptionData = response.data
        errorMessage = exceptionData.message
      }
      
      dispatch('setError',{hasError: true, error: errorMessage})
    }
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const sendReimbursementFiles = async ({ commit, state, rootState, dispatch }, reimbursementId) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const filesByField = state.files;
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const userName = rootState.auth.tokenExtensions.userName;
    
    for (let i = 0; i < filesByField.length; i++) {

      const fieldFile = filesByField[i];
      const files = fieldFile.files;
      for (let j = 0; j < files.length; j++) {
        const file = files[j];
        await arcService.sendFile(fieldFile.fieldSlug, file, reimbursementId);
        Bugsnag.notify(new Error('sending files'), function (event) {
          event.severity = 'info'
          event.context = 'sending-files'
          event.addMetadata('reimbursement-detail', { reimbursementId, beneficiaryId, userName, file: fieldFile.fieldSlug })
        })
      }
    }
    dispatch('completeReimbursement', reimbursementId)
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    dispatch('setReimbursementToErrorStatus', reimbursementId)
  }
}

const setReimbursementToErrorStatus = async ({commit, dispatch}, reimbursementId) => {
  commit(AUTHORIZATION_LOADING, true)
  try{
    await arcService.setReimbursementToErrorStatus(reimbursementId);
    commit(SET_CURRENT_STEP, 1)    
    commit(RESET_AUTHORIZATION_STATE)    
    dispatch('setReimbursementStatus', 'ERROR')
    router.push('/home')
  }catch(error){
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const completeReimbursement = async ({ commit, dispatch }, reimbursementId) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    await arcService.completeReimbursement(reimbursementId)
    commit(SET_CURRENT_STEP, 1)    
    commit(AUTHORIZATION_LOADING, false)
    commit(RESET_AUTHORIZATION_STATE)
    dispatch('setReimbursementStatus', 'SUCCESS')
    router.push('/home')
  } catch (error) {
    const response = error.response
    if (response) {
      let errorMessage = null
      const status = response.status
      if (status === 422) {
        const exceptionData = response.data
        if (exceptionData.errors) {
          if (exceptionData.errors.length > 0) {
            errorMessage = exceptionData.errors[0].message;
          } 
        }
      } else if (status === 500) {
        const exceptionData = response.data
        errorMessage = exceptionData.message
      }
      
      dispatch('setError',{hasError: true, error: errorMessage})
    }
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
    dispatch('setReimbursementToErrorStatus', reimbursementId)
  }
}

const sendAuthorization = async ({ commit, state, rootState, dispatch }) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const request = decamelizeKeys({ beneficiaryId, formSlug: state.currentForm, inputs: state.inputs })
    const response = await arcService.sendAuthorization(request)
    const authorization = camelizeKeys(response.data)
    dispatch('sendAuthorizationFiles', authorization.id)
  } catch(error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const sendAuthorizationFiles = async ({ commit, state, dispatch }, authorizationId) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    const filesByField = state.files;

    for (let i = 0; i < filesByField.length; i++) {

      const fieldFile = filesByField[i];
      const files = fieldFile.files;
      for (let j = 0; j < files.length; j++) {
        const file = files[j];
        await arcService.sendAuthorizationFile(fieldFile.fieldSlug, file, authorizationId);
      }
    }
    dispatch('completeAuthorization', authorizationId)
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    dispatch('setAuthorizationToErrorStatus', authorizationId)    
  }
}

const setAuthorizationToErrorStatus = async ({commit, dispatch}, authorizationId) => {
  commit(AUTHORIZATION_LOADING, true)
  try{
    await arcService.setAuthorizationToErrorStatus(authorizationId);
    commit(SET_CURRENT_STEP, 1)    
    commit(RESET_AUTHORIZATION_STATE)
    dispatch('setReimbursementStatus', 'ERROR')
    router.push('/home')
  }catch(error){
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const completeAuthorization = async ({ commit, dispatch }, authorizationId) => {
  commit(AUTHORIZATION_LOADING, true)
  try {
    await arcService.completeAuthorization(authorizationId)
    commit(SET_CURRENT_STEP, 1)    
    commit(AUTHORIZATION_LOADING, false)
    commit(RESET_AUTHORIZATION_STATE)
    dispatch('setReimbursementStatus', 'SUCCESS')
    router.push('/home')
  } catch (error) {
    commit(AUTHORIZATION_LOAD_ERROR)
    commit(AUTHORIZATION_LOADING, false)
  }
}

const setError = ({ commit }, payload) => {
  commit(SET_ERROR, payload);
}

const setDetailFiles = ({ commit }, payload) => {
  commit(ADD_FILES, payload)
}

const setFiles = async ({ commit, state }, payload) => {
  commit(SET_LOADING_FILE, true)
  try {
    const fieldFiles = Array.from(payload.files)
    for (let i = 0; i < fieldFiles.length; i++) {
      const file = fieldFiles[i]
      const formSlug = state.currentForm
      const uploadedFile = await arcService.sendFileV2(formSlug, payload.fieldSlug, file)
      state.files.push(camelizeKeys(uploadedFile.data))
    }

    commit(ADD_FILES, state.files)
    commit(SET_LOADING_FILE, false)
  } catch (error) {
    commit(SET_LOADING_FILE, false)
    commit(AUTHORIZATION_LOAD_ERROR)
  }
}

const removeFile = ({ commit }, id) => {
  commit(REMOVE_FILE, id)
}

const setStepError = ({ commit }, payload) => {
  commit(SET_STEP_ERROR, payload)
}

const setInput = ({ commit, state }, payload) => {
  const { slug, value } = payload;
  const inputs = state.inputs;
  const index = inputs.findIndex(input => input.slug === slug);
  if (index >= 0) {
    inputs[index].value = value;
  } else {
    inputs.push({ slug, value });
  }

  commit(SET_INPUTS, inputs)
}

const removeInput = ({ commit, state }, payload) => {
  const inputs = state.inputs;
  const inputsFiltered = inputs.filter(input => input.slug != payload)
  commit(SET_INPUTS, inputsFiltered)
}

const setReimbursementStatus = ({ commit }, payload) => {
  commit(SET_REIMBURSEMENT_STATUS, payload)
}

const setClaimType = ({ commit }, payload) => {
  commit(SET_CLAIM_TYPE, payload)
}

const resetAuthorizationState = ({ commit }) => {
  commit(RESET_AUTHORIZATION_STATE)
}

const setSingleAuthorization = async ({ commit }, payload) => {
  sessionStorage.setItem('listAuthorization', JSON.stringify(payload))
  commit(SET_LIST_AUTHORIZATION, payload)
  commit(SET_AUTHORIZATION, {})
}

const getAuthorizationDetail = async ({ commit, rootState, dispatch, state }, payload) => {
  try {
    const beneficiaryId = rootState.auth.tokenExtensions.beneficiaryId;
    const { statusConfiguration, iconConfiguration, type } = state.listAuthorization || {}
    const authorizationResponse = await arcService.getAuthorization(beneficiaryId, payload)
    let authorization = camelizeKeys(authorizationResponse.data)
    authorization = 
    {
      ...authorization, 
      statusLabel: arcService.getStatus(authorization),
      statusConfiguration, iconConfiguration, type
    }
    sessionStorage.setItem('authorization', JSON.stringify(authorization))
    commit(SET_AUTHORIZATION, authorization)
    commit(AUTHORIZATION_COMMENT_LOADING, false);
    dispatch('setIsReimbursement', authorization.isReimbursement)
    if (!state.authorizationFormsAndSteps) {
      dispatch('getAuthorizationsForm')
    }    
  } catch(error) {
    commit(SET_AUTHORIZATION_ERROR, error)
    commit(AUTHORIZATION_COMMENT_LOADING, false);
    router.push('/home')
  }
}

const sendReimbursementFilesWithComment = async ({ commit, state, dispatch, getters }) => {
  commit(AUTHORIZATION_LOADING, true)
 
  const filesByField = state.files;
  const commentFiles = [];
  for (let i = 0; i < filesByField.length; i++) {

    const fieldFile = filesByField[i];
    const files = fieldFile.files;
    for (let j = 0; j < files.length; j++) {
      const file = files[j];
      const commentFile = { name: file.name, type: file.type}
      commentFiles.push(commentFile)
      const { authorization } = getters;
      if (authorization.isReimbursement) {
        await arcService.sendFile(fieldFile.fieldSlug, file, authorization.reimbursementId);
      } else {
        await arcService.sendAuthorizationFile(fieldFile.fieldSlug, file, authorization.authorizationId);
      }
    }
  }

  const metadataCommentDecamelize = decamelizeKeys({ fallbackLabel: filesByField[0].fallbackLabel, slug: filesByField[0].fieldSlug, commentFiles})
  const metadataComment = JSON.stringify(metadataCommentDecamelize)
  const comment = vuei18n.t('authorizationDetail.observations.addOtherFileComment')
  const payload = decamelizeKeys({ comment, privateComment: false, beneficiaryComment: true, metadataComment })
  
  dispatch('sendComment', payload)
  commit(AUTHORIZATION_LOADING, false)
}

const sendBeneficiaryComment = async ({ commit, state, dispatch}, payload) => {
  commit(AUTHORIZATION_COMMENT_LOADING, true)
  const comment = decamelizeKeys({ comment: payload, privateComment: false, beneficiaryComment: true })
  await dispatch('sendComment', comment);
  const authorizationCode = state.authorization.authorizationCode;
  await dispatch('getAuthorizationDetail', authorizationCode);
}

const sendComment = async ({ state }, payload) => {
  await arcService.sendComment(state.authorization.authorizationCode, payload)
}

const setIsReimbursement = ({ commit }, payload) => {
  commit(SET_IS_REIMBURSEMENT, payload)
}

export default {
  getAuthorizations,
  getAuthorizationsForm,
  getSponsorConfiguration,
  setCurrentForm,
  setCurrentStep,
  nextStep,
  setError,
  setFiles,
  setStepError,
  sendReimbursement,
  sendReimbursementFiles,
  getBeneficiary,
  setInput,
  removeInput,
  setReimbursementStatus,
  setClaimType,
  resetAuthorizationState,
  lastStep,
  completeReimbursement,
  setSingleAuthorization,
  getAuthorizationDetail,
  sendComment,
  sendReimbursementFilesWithComment,
  sendBeneficiaryComment,
  sendAuthorization,
  sendAuthorizationFiles,
  completeAuthorization,
  setIsReimbursement,
  setAuthorizationToErrorStatus,
  setReimbursementToErrorStatus,
  setCurrentCoverage,
  sendReimbursementV2,
  removeFile,
  setDetailFiles
}