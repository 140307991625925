import {
  AUTHORIZATION_LOAD_ERROR,
  AUTHORIZATION_LOAD_SUCCESS,
  AUTHORIZATION_LOADING,
  AUTHORIZATION_COMMENT_LOADING,
  AUTHORIZATION_FORM_LOAD_SUCCESS,
  AUTHORIZATION_SPONSOR,
  SET_CURRENT_FORM,
  SET_CURRENT_STEP,
  SET_ERROR,
  ADD_FILES,
  SET_STEP_ERROR,
  SET_BENEFICIARY,
  SET_BENEFICIARY_ERROR, 
  SET_INPUTS,
  RESET_AUTHORIZATION_STATE,
  SET_REIMBURSEMENT_STATUS,
  SET_CLAIM_TYPE,
  SET_LIST_AUTHORIZATION,
  SET_AUTHORIZATION,
  SET_AUTHORIZATION_ERROR,
  SET_SPONSOR_SLUG,
  SET_IS_REIMBURSEMENT,
  SET_COUNTRY_CODE,
  SET_CURRENCY_CODE,
  SET_CURRENT_COVERAGE,
  REMOVE_FILE,
  SET_LOADING_FILE
} from '../mutation-types';

export default {
  [AUTHORIZATION_LOAD_SUCCESS] (state, payload) {
    state.authorizations = payload.content
    state.links = payload.links
    state.page = payload.page
  },
  [AUTHORIZATION_LOAD_ERROR] (state) {
    state.errorAlert = true
  },
  [AUTHORIZATION_LOADING] (state, payload) {
    state.authorizationLoading = payload
  },
  [AUTHORIZATION_COMMENT_LOADING] (state, payload) {
    state.authorizationCommentLoading = payload
  },
  [AUTHORIZATION_FORM_LOAD_SUCCESS] (state, payload) {
    state.authorizationForms = payload.response
    state.authorizationFormsAndSteps = payload.formsByStep
  },
  [AUTHORIZATION_SPONSOR] (state, payload) {
    state.sponsorConfigurations = payload
  },
  [SET_CURRENT_FORM] (state, payload) {
    state.currentForm = payload
  },
  [SET_CURRENT_STEP] (state, payload) {
    state.currentStep = payload
  },
  [SET_CURRENT_COVERAGE] (state, payload) {
    state.currentCoverage = payload
  },
  [SET_ERROR] (state, payload) {
    state.error = payload
  },
  [ADD_FILES] (state, payload) {
    state.files = payload
  },
  [REMOVE_FILE](state, id) {
    state.files = state.files.filter((file) => file.fileId !== id)
  },
  [SET_STEP_ERROR] (state, payload) {
    state.stepError = payload
  },
  [SET_BENEFICIARY] (state, payload) {
    state.beneficiary = payload
  },
  [SET_BENEFICIARY_ERROR] (state, payload) {
    state.sponsorSlugError = payload
  },
  [SET_INPUTS] (state, payload) {
    state.inputs = payload
  },
  [RESET_AUTHORIZATION_STATE] (state) {
    Object.assign(state, { files: [], inputs: [], sponsorSlugError: null, currentForm: null, claimType: null, currentCoverage: null })
  },
  [SET_REIMBURSEMENT_STATUS] (state, payload) {
    state.reimbursementStatus = payload
  },
  [SET_CLAIM_TYPE] (state, payload) {
    state.claimType = payload
  },
  [SET_LIST_AUTHORIZATION] (state, payload) {
    state.listAuthorization = payload
  },
  [SET_AUTHORIZATION] (state, payload) {
    state.authorization = payload
  },
  [SET_AUTHORIZATION_ERROR] (state, payload) {
    state.authorizationError = payload
  },
  [SET_SPONSOR_SLUG] (state, payload) {
    state.sponsorSlug = payload
  },
  [SET_IS_REIMBURSEMENT] (state, payload) {
    state.isReimbursement = payload
  },
  [SET_COUNTRY_CODE](state, payload) {
    state.countryCode = payload
  },
  [SET_CURRENCY_CODE](state, payload) {
    state.currencyCode = payload
  },
  [SET_LOADING_FILE](state, payload) {
    state.loadingFile = payload
  }
}