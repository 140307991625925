import store from '@/plugins/vuex'
const Login = () => import('@/components/login/Login');
const ForgotPassword = () => import('@/components/forgot-password/ForgotPassword');
const ChangePassword = () => import('@/components/change-password/ChangePassword');
const Home = () => import('@/components/home/Home');
const HomeLayout = () => import('@/components/home-layout/HomeLayout');
const AuthorizationLayout = () => import('@/components/authorization/AuthorizationLayout');
const AddAuthorizationLayout = () => import('@/components/authorization/add-authorization/AddAuthorizationLayout');
const AuthorizationDetailLayout = () => import('@/components/authorization/authorization-detail/AuthorizationDetailLayout');
const AgreementDocumentView = () => import('@/components/agreement/AgreementDocumentView');


const ifNotAuthenticated = (to, from, next) => {
  next()
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
    return
  }

  next('/login')
}

const ifAuthenticatedAndHasntDocumentsToAgree = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    if (store.getters['agreements/hasPendingDocumentsToAgree']) {
      next('/agreement') 
      return   
    } 
    
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    props: (route) => ({ token: route.query.token }),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/',
    component: HomeLayout,
    beforeEnter: ifAuthenticated,
    redirect: { name: 'Home' },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        beforeEnter: ifAuthenticatedAndHasntDocumentsToAgree,
      },
      {
        path: '/agreement',
        name: 'AgreementDocuments',
        component: AgreementDocumentView,
        beforeEnter: ifAuthenticated,
      },
    ]
  },
  {
    path: '/authorization',
    name: 'Authorization',
    component: AuthorizationLayout,
    beforeEnter: ifAuthenticatedAndHasntDocumentsToAgree,
    children: [
      {
        path: 'new',
        name: 'AddAuthorization',
        component: AddAuthorizationLayout,
        beforeEnter: ifAuthenticatedAndHasntDocumentsToAgree,
      },
      {
        path: ':authorization',
        name: 'AuthorizationDetail',
        component: AuthorizationDetailLayout,
        beforeEnter: ifAuthenticatedAndHasntDocumentsToAgree,
      },
      {
        path: '*',
        redirect: { name: 'Home' },
      }
    ]
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  }
]

export default routes;