import vuei18n from '@/plugins/i18n';
import { validateDate, validateIsDateSameOrBefore, validateIsDateSameOrAfter, validateDateFormat } from '@/utils'

const EMAIL_REGEX =  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const LOWER_CASE_REGEX = /[a-z]/;
const UPPER_CASE_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;
const DECIMAL_FORMAT = /^((\d+)|(\d{1,3}(,\d{3})+))(\.\d{2})?$/;

const inputRules = {
  required: value => !!value || vuei18n.t('errors.inputValidations.required'),
  passwordConfirmation: (x, y) => x === y || vuei18n.t('changePassword.badConfirmPassword'),
  email: value => {
    if (!value) {
      return true;
    }
    return EMAIL_REGEX.test(value) || vuei18n.t('errors.inputValidations.invalidFormat')
  },
  maxLength: (value, maxLength) => {
      if (!value || !maxLength) {
          return true;
      }

      return value.length <= maxLength || vuei18n.tc('errors.inputValidations.maxLength', [maxLength])
  },
  minLength: (value, minLength) => {
      if (!value  || !minLength) {
          return true;
      }

      return value.length >= minLength || vuei18n.tc('errors.inputValidations.minLength', [minLength])
  },
  maxCharactersLength: maxLength => value => {
      if (!value) {
          return true;
      }

      return value.length <= maxLength || vuei18n.tc('errors.inputValidations.maxLength', [maxLength])
  },
  fileLimit: (value, maxFiles) => {
    if (!value) {
      return true;
    }
    return value.length <= maxFiles || vuei18n.tc('errors.inputValidations.maxFiles', [maxFiles])
  },
  min: value =>
    (value && value.length >= 8) || vuei18n.t('updatePassword.passwordMinLength'),
  lowerCase: value => 
    (value && LOWER_CASE_REGEX.test(value)) || vuei18n.t('updatePassword.passwordLowerCase'),
  upperCase: value =>
    (value && UPPER_CASE_REGEX.test(value)) || vuei18n.t('updatePassword.passwordUpperCase'),
  number: value =>
    (value && NUMBER_REGEX.test(value)) || vuei18n.t('updatePassword.number'),
  zero: value => {
    if (!value) {
      return true;
    }

    const valueFloatParsed = parseFloat(value.toString().replace(/,/g, '')) || null;
    return valueFloatParsed > 0 || vuei18n.t('errors.inputValidations.zero')
  },
  dateFormat: value => {
    if (!value) {
      return true;
    }

    return validateDateFormat({ value }) || vuei18n.t('errors.inputValidations.invalidDate')
  },
  date: value => {
    if (!value) {
      return true;
    }

    return validateDate({ value }) || vuei18n.t('errors.inputValidations.invalidDate')
  },
  dateAfter: value => {
    if(!value){
      return true;
    }

    return validateIsDateSameOrAfter({ value }) || vuei18n.t('errors.inputValidations.invalidDate')
  },
  dateBefore: (date1, date2) => {
    return validateIsDateSameOrBefore(date1, date2) || vuei18n.t('errors.inputValidations.invalidBeforeDate')
  },
  diagnosisRepeated: (value, diagnosisType) => {
    const message = diagnosisType === 'MEDICAL_DIAGNOSIS' ? 'diagnoses.errors.repeated' : 'patients.allergies.errors.repeated';

    return !value || vuei18n.t(message)
  },
  maxValue: (value, maxValue) => {
    if (!value || !maxValue) {
        return true;
    }
    return value && value <= maxValue || vuei18n.tc('errors.inputValidations.maxValue', [maxValue])
  },
  maxInputValue: maxValue => value => {
    const maxValueFloatParsed = parseFloat(maxValue.toString().replace(/,/g, '')) || null;
    if (!value) {
        return true;
    }

    const parsedValue = parseFloat(value.toString().replace(/,/g, '')) || null;
    return parsedValue && parsedValue <= maxValueFloatParsed || vuei18n.tc('errors.inputValidations.maxInputValue', [maxValue])
  },
  minInputValue: minValue => value => {
    if (!value) {
        return true;
    }
    
    const parsedValue = parseFloat(value.toString().replace(/,/g, '')) || null    
    return parsedValue && parsedValue >= minValue || vuei18n.tc('errors.inputValidations.minInputValue', [minValue] )
  },
  validatePercentage: sumOfPercentages => {
    return sumOfPercentages <= 100 || vuei18n.t('errors.inputValidations.invalidPercentages')
  },
  validateTotalPercentage: sumOfPercentages => {
    return sumOfPercentages === 100 || vuei18n.t('errors.inputValidations.invalidTotalPercentage')
  },
  maxAndMinWithZeroValue: (minValue, maxValue) => value => {
    if(!value){
      return true
    }

    const parsedValue = parseFloat(value.toString().replace(/,/g, '')) || null

    if(!parsedValue){
      return true
    }

    return parsedValue && parsedValue <= maxValue && parsedValue >= minValue || vuei18n.t('errors.inputValidations.invalid', [minValue, maxValue])
  },
  isValidDecimalFormat : value => {
    if (!value) {
      return true;
    }

    return value && DECIMAL_FORMAT.test(value) || vuei18n.t('errors.inputValidations.invalidDecimalFormat')
  }
}

function getFieldsByForm(authorizationForms) {
  const firstStep = authorizationForms
    .map(form => ({ key: form.formSlug, label: form.fallbackName, coverageType: form.authorizationCoverage}));
  
  const forms = authorizationForms
  .map(form => ({ form: form.formSlug }))

  const fieldsByForm = forms.reduce((acc, current) => {
    return {
      ...acc,
      [current.form]: [{
          grouped: false,
          fields: 
          [
            {
              required: true,
              label: vuei18n.t('addAuthorization.fields.type'),
              type: 'SELECT',
              options: firstStep,
              slug: 'forms',
              description: vuei18n.t('addAuthorization.fields.description'),
              groupName: vuei18n.t('addAuthorization.fields.type')
            }
          ]
        }
      ]
    }
  }, {});
  
  authorizationForms.forEach(form => {
    const fieldsByGroup = getFormFields(form).reduce((actual, current) => {
      actual[current.groupName] = actual[current.groupName] || [];
      actual[current.groupName].push(current);
      return actual;
    }, {})
    let mins = []
    Object.keys(fieldsByGroup)
    .forEach(group => {
      const min = fieldsByGroup[group].reduce((actual, current) => {
        return actual.showOrder < current.showOrder ? actual : current; 
      })
      mins.push(min);
    })  

    const order = mins.sort((a,b) => {
      if (a.showOrder > b.showOrder) return 1;
      if (b.showOrder > a.showOrder) return -1;
      return 0;
    });

    order.forEach(step => {
      let formGroup;
      Object.keys(fieldsByGroup)
      .forEach(group => {
        const act = fieldsByGroup[group].find(value => value === step);
        if (act) {
          formGroup = fieldsByGroup[group].sort((a,b) => {
            if (a.showOrder > b.showOrder) return 1;
            if (b.showOrder > a.showOrder) return -1;
            return 0;
          });
        }
      })

      const groupFields = []
      const fieldsByGroupField = groupBy(formGroup.filter(field => field.groupField), 'groupField')
      if (Object.keys(fieldsByGroupField).length > 0) {
        const groupedFields = []
        Object.keys(fieldsByGroupField).forEach(group => {
          groupedFields.push({ group, fields: fieldsByGroupField[group], groupName: fieldsByGroupField[group][0].groupName })
        })
        groupFields.push({grouped: true, fields: groupedFields})
      }

      const withOutGroup = formGroup.filter(field => !field.groupField)
      if (withOutGroup.length > 0) {
        groupFields.push({ grouped: false, fields: withOutGroup })
      }

      fieldsByForm[form.formSlug].push(...groupFields);
      formGroup = null;
    })
  })

  return fieldsByForm;
}

function groupBy(array, key) {
  return array.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function getFormFields(form) {
  const { authorizationCoverage, fields } = form
  if (creditInsurance.includes(authorizationCoverage)) {
    return fields.filter(field => field.slug !== 'settlement_files')
  }
  return fields
}

const dynamicCoverages = ['DIAGNOSTIC_TESTS', 'OUTPATIENT_CARE', 'PRESCRIPTION_DRUGS', 'MEDICAL_PROCEDURE', 'CREDIT_CARD_BALANCE', 'PERMANENT_DISABILITY', 'UNEMPLOYMENT', 'FUNERAL_ASSISTANCE', 'PPI', 'TEMPORARY_DISABILITY', 'SECOND_ASSESSMENT', 'ACCIDENT_MEDICAL_REIMBURSEMENT', 'DAILY_HOSPITALIZATION_INCOME', 'CANCER_DIAGNOSIS', 'DIABETES_ACCIDENTAL_DEATH', 'DIABETES', 'CELL_PROTECTION', 'CASH_ROBBERY']
const customArrayJsonFields = ['DIAGNOSES', 'DRUG_PRODUCT', 'MEDICAL_PROCEDURE_PRODUCT', 'LABORATORY_PRODUCT', 'DIAGNOSTIC_TEST_PRODUCT', 'BENEFICIARY']
const customObjectJsonFields = ['DOCTOR', 'PRODUCT_INVOICE']
const fieldsWithAddButton = ['BENEFICIARY', 'FILE_UPLOAD']
const creditInsurance = ['CREDIT_CARD_BALANCE', 'PERMANENT_DISABILITY', 'UNEMPLOYMENT', 'FUNERAL_ASSISTANCE', 'PPI', 'TEMPORARY_DISABILITY', 'SECOND_ASSESSMENT', 'ACCIDENT_MEDICAL_REIMBURSEMENT', 'DAILY_HOSPITALIZATION_INCOME', 'CANCER_DIAGNOSIS', 'DIABETES_ACCIDENTAL_DEATH', 'DIABETES', 'CELL_PROTECTION', 'CASH_ROBBERY']
const secondAssessmentCoverageType = 'SECOND_ASSESSMENT';

const customObjectJsonFieldConfiguration = [
  {
    type: 'DOCTOR',
    fields: [
      {
        slug: 'name',
        label: 'addAuthorization.customObjectFields.doctor.name',
        required: true
      },
      {
        slug: 'medicalLicense',
        label: 'addAuthorization.customObjectFields.doctor.medicalLicense'
      },
      {
        slug: 'specialty',
        label: 'addAuthorization.customObjectFields.doctor.specialty'
      }
    ]
  },
  {
    type: 'PRODUCT_INVOICE',
    fields: [
      {
        slug: 'taxIdentificationNumber',
        label: 'addAuthorization.customObjectFields.productInvoice.taxIdentificationNumber'
      },
      {
        slug: 'providerName',
        label: 'addAuthorization.customObjectFields.productInvoice.providerName',
        required: true
      },
      {
        slug: 'invoiceNumber',
        label: 'addAuthorization.customObjectFields.productInvoice.invoiceNumber'
      },
      {
        slug: 'invoiceDate',
        label: 'addAuthorization.customObjectFields.productInvoice.invoiceDate',
        type: 'DATE'
      },
    ]
  }
]

export { 
  inputRules, 
  getFieldsByForm, 
  groupBy, 
  customArrayJsonFields, 
  customObjectJsonFields, 
  customObjectJsonFieldConfiguration, 
  dynamicCoverages, 
  fieldsWithAddButton, 
  creditInsurance,
  secondAssessmentCoverageType
}