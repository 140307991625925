export default {
    accessToken: (state) => state.accessToken,
    tokenType: (state) => state.tokenType,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated: (state) => !!state.accessToken,
    tokenExtensions: (state) => state.tokenExtensions,
    authStatus: (state) => state.authStatus,
    usedEmail: (state) => state.email,
    beneficiaryAttributes: (state) => [{
        key: 'beneficiary_id',
        value: state.tokenExtensions.beneficiaryId
    }]
}