export default {
  authorizations: [],
  page: {},
  links: {},
  authorizationLoading: false,
  authorizationCommentLoading: false,
  authorizationForms: [],
  authorizationFormsAndSteps: null,
  sponsorConfigurations:{},
  currentForm: null,
  currentStep: null,
  currentCoverage: null,
  reimbursementStatus: null,
  error: {},
  files: [],
  beneficiary: {},
  sponsorSlug: sessionStorage.getItem('sponsorSlug') || null,
  countryCode: sessionStorage.getItem('countryCode') || null,
  currencyCode: sessionStorage.getItem('currencyCode') || null,
  sponsorSlugError: {},
  stepError: true,
  inputs: [],
  claimType: null,
  listAuthorization: JSON.parse(sessionStorage.getItem('listAuthorization')) || {},
  authorization: JSON.parse(sessionStorage.getItem('authorization')) || {},
  authorizationError: null,
  isReimbursement: null,
  loadingFile: null
}