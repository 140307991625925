<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import { config } from '@/plugins/env.config'
export default {
  name: 'App',
  beforeCreate(){
    const favicon = document.getElementById("favicon")
    if(config.theme === 'METLIFE'){
      favicon.href = process.env.BASE_URL+"favicon-metlife.ico"
    }
    else{
      favicon.href = process.env.BASE_URL+"favicon.ico"
    }
  }
};
</script>
