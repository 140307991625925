export const SET_TOKEN = 'SET_TOKEN'
export const SET_STATUS_AUTH_ERROR = 'SET_STATUS_AUTH_ERROR'
export const SET_STATUS_AUTH_LOGOUT = 'SET_STATUS_AUTH_LOGOUT'
export const SET_STATUS_AUTH_REQUEST = 'SET_STATUS_AUTH_REQUEST'
export const SET_STATUS_AUTH_SUCCESS = 'SET_STATUS_AUTH_SUCCESS'
export const CLIENT_TOKEN_SUCCESS = 'CLIENT_TOKEN_SUCCESS'
export const CLIENT_TOKEN_ERROR = 'CLIENT_TOKEN_ERROR'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const RESET_CHANGE_PASSWORD_STATUS = 'RESET_CHANGE_PASSWORD_STATUS'
export const AUTHORIZATION_LOAD_SUCCESS = 'AUTHORIZATION_LOAD_SUCCESS'
export const AUTHORIZATION_LOAD_ERROR = 'AUTHORIZATION_LOAD_ERROR'
export const AUTHORIZATION_LOADING = 'AUTHORIZATION_LOADING'
export const AUTHORIZATION_COMMENT_LOADING = 'AUTHORIZATION_COMMENT_LOADING'
export const AUTHORIZATION_FORM_LOAD_SUCCESS = 'AUTHORIZATION_FORM_LOAD_SUCCESS'
export const AUTHORIZATION_SPONSOR = 'AUTHORIZATION_SPONSOR'
export const SET_CURRENT_FORM = 'SET_CURRENT_FORM'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_ERROR = 'SET_ERROR'
export const ADD_FILES = 'ADD_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const SET_STEP_ERROR = 'SET_STEP_ERROR'
export const SET_BENEFICIARY = 'SET_BENEFICIARY'
export const SET_BENEFICIARY_ERROR = 'SET_BENEFICIARY_ERROR'
export const SET_INPUTS = 'SET_INPUTS'
export const RESET_AUTHORIZATION_STATE = 'RESET_AUTHORIZATION_STATE'
export const SET_REIMBURSEMENT_STATUS = 'SET_REIMBURSEMENT_STATUS'
export const SET_CLAIM_TYPE = 'SET_CLAIM_TYPE'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_LIST_AUTHORIZATION = 'SET_LIST_AUTHORIZATION'
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION'
export const SET_AUTHORIZATION_ERROR = 'SET_AUTHORIZATION_ERROR'
export const SET_SPONSOR_SLUG = 'SET_SPONSOR_SLUG'
export const SET_PENDING_AGREEMENT_DOCUMENTS = 'SET_PENDING_AGREEMENT_DOCUMENTS'
export const SET_PENDING_HTML_DOCUMENTS = 'SET_PENDING_HTML_DOCUMENTS'
export const SET_ERROR_AGREEMENT = 'SET_ERROR_AGREEMENT'
export const SET_IS_REIMBURSEMENT = 'SET_IS_REIMBURSEMENT'
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
export const SET_CURRENCY_CODE = 'SET_CURRENCY_CODE'
export const SET_CURRENT_COVERAGE = 'SET_CURRENT_COVERAGE'
export const SET_LOADING_FILE = 'SET_LOADING_FILE'
